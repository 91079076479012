import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import * as Yup from "yup";
import { alertsMapSendSign } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { formatAmount } from "../../app/services/functionServices";
import { useRequest } from "../../app/services/userHttpRequests";
import { KTSVG } from "../../_oxapay/helpers";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { WalletWebappSendContext } from "../../_oxapay/layout/core/WalletWebappSendContext";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { WebappSuccessTransferAlert } from "./AlertsModal/WebappSuccessTransferAlert";
import { WebappInput } from "./WebappInput";
import { WebappTextArea } from "./WebappTextArea";

interface props {
  onSuccess: () => void;
  onBack: () => void;
}

export const WalletWebappSendStepThree = ({ onSuccess, onBack }: props) => {
  const [loading, setLoading] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const { sendAssetSign } = useRequest();
  const {
    stepThreeOptions,
    setStepThreeOptions,
    setStepTwoOptions,
    stepTwoOptions,
  } = useContext(WalletWebappSendContext);
  const { coinsListMap } = useContext(AppContext);
  const [networkName, setNetworkName] = useState<string>();
  const { showModal, setShowModal, setComponent } =
    useContext(WebappModalContext);

  useEffect(() => {
    if (stepThreeOptions?.coin) {
      const Coin = coinsListMap[stepThreeOptions?.coin];
      if (Coin && Coin.networkList) {
        const name = Coin.networkList[stepThreeOptions.network].name;
        setNetworkName(name);
      }
    }
    // eslint-disable-next-line
  }, []);

  const validationSchema =
    stepThreeOptions?.twoFa && stepThreeOptions?.emailconfirmation
      ? Yup.object({
          verification: Yup.string().required("Verification code is required"),
          twoFa: Yup.string().required(
            "Two-factor authentication code is required"
          ),
        })
      : stepThreeOptions?.twoFa === false && stepThreeOptions?.emailconfirmation
      ? Yup.object({
          verification: Yup.string().required("Verification code is required"),
          twoFa: Yup.string(),
        })
      : stepThreeOptions?.twoFa && stepThreeOptions?.emailconfirmation === false
      ? Yup.object({
          verification: Yup.string(),
          twoFa: Yup.string().required(
            "Two-factor authentication code is required"
          ),
        })
      : stepThreeOptions?.twoFa === false &&
        stepThreeOptions?.emailconfirmation === false &&
        Yup.object({
          verification: Yup.string(),
          twoFa: Yup.string(),
        });

  const formik = useFormik({
    initialValues: {
      verification: "",
      twoFa: "",
      note: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (stepThreeOptions?.requestToken) {
          const res = await sendAssetSign(
            stepThreeOptions?.requestToken,
            values.verification,
            values.twoFa,
            values.note
          );
          if (res.status === 200) {
            setShowModal(true);
            setComponent(
              <WebappSuccessTransferAlert
                coinsListMap={coinsListMap}
                txData={res.tx}
                content={"The trasnfer was successful"}
              />
            );
            onSuccess();
            setStepTwoOptions(undefined);
            setStepThreeOptions(undefined);
          } else {
            setShowModal(true);
            setComponent(
              <WebappFailAlert
                content={alertsMapSendSign[res.errorNum.toString()]}
              />
            );
          }
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });
  const WebappFn = (window as any)?.Telegram?.WebApp;

  useEffect(() => {
    const backBtnFn3 = () => {
      onBack();
    };

    WebappFn?.BackButton?.onClick(backBtnFn3).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn3).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!showModal) {
      if (loading) {
        WebappFn?.MainButton?.show()
          .setText("Please wait")
          .offClick(formik.handleSubmit)
          .showProgress(true);
      } else {
        WebappFn?.MainButton?.show()
          .setText("Next")
          .onClick(formik.handleSubmit)
          .hideProgress();
      }
    }

    return () => {
      WebappFn?.MainButton?.hide().offClick(formik.handleSubmit).hideProgress();
    };
    // eslint-disable-next-line
  }, [loading, showModal]);

  return (
    <>
      <div className="pt-10 mb-7">
        {stepThreeOptions?.emailconfirmation && (
          <div className="mb-7">
            <WebappInput
              type="text"
              name="verification"
              placeholder="Code"
              autoComplete="off"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.verification}
              error={
                formik.errors.verification && formik.touched.verification
                  ? true
                  : false
              }
              label={`${
                stepThreeOptions?.source &&
                stepThreeOptions?.source.charAt(0).toUpperCase() +
                  stepThreeOptions?.source.slice(1)
              }{" "}
              verification code`}
              clear={() => formik.setFieldValue("verification", "")}
            />
            {formik.errors.verification && formik.touched.verification && (
              <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                {formik.errors.verification}
              </p>
            )}
            <p className="fs-6 lh-normal m-0 px-5 mt-3">
              <span className="bg-primary text-white px-2 rounded fs-7 me-1">
                i
              </span>
              The verification code was sent to your{" "}
              <b className="text-primary">{stepThreeOptions?.source}</b>
            </p>
          </div>
        )}
        {stepThreeOptions?.twoFa && (
          <>
            <WebappInput
              label="2-FA verification code"
              type="number"
              inputMode="numeric"
              name="twoFa"
              placeholder="Code"
              autoComplete="off"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.twoFa}
              error={formik.errors.twoFa && formik.touched.twoFa ? true : false}
              clear={() => formik.setFieldValue("twoFa", "")}
            />
            {formik.errors.twoFa && formik.touched.twoFa && (
              <p className="text-danger fw-semibold fs-5 ms-3 mt-1 mb-0">
                {formik.errors.twoFa}
              </p>
            )}
            <p className="fs-6 lh-normal m-0 px-5 mt-3">
              <span className="bg-primary text-white px-2 rounded fs-7 me-1">
                i
              </span>
              The code is found in your authenticator app, like Google
              Authenticator or others.
            </p>
          </>
        )}
      </div>

      <p className="fs-6 text-uppercase fw-400 text-gray-600 mb-2 ps-4">
        Transfer details
      </p>
      <div className="card rounded-12px ps-6 py-4">
        <div>
          <p className="text-dark fs-2 mb-0">Currency</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {stepThreeOptions?.coin &&
              coinsListMap[stepThreeOptions?.coin].name}{" "}
            ({stepThreeOptions?.coin})
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        {!stepTwoOptions?.intenal && (
          <>
            <div>
              <p className="text-dark fs-2 mb-0">Network</p>
              <p className="text-primary fs-5 fw-400 mb-0 pe-3">
                {networkName === null ? stepThreeOptions?.network : networkName}
              </p>
            </div>
            <hr className="border-gray-500 w-100 mx-0 my-3" />
          </>
        )}

        <div>
          <p className="text-dark fs-2 mb-0">Amount</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {stepThreeOptions &&
              formatAmount(
                parseFloat(stepThreeOptions?.amount),
                coinsListMap[stepThreeOptions?.coin]?.displayPrecision
              )}{" "}
            {stepThreeOptions?.coin}
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Fee</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {stepThreeOptions?.fee} {stepThreeOptions?.coin}
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Receiver Address</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {stepThreeOptions?.address}
          </p>
        </div>

        {stepThreeOptions?.memo && (
          <>
            <hr className="border-gray-500 w-100 mx-0 my-3" />
            <div>
              <p className="text-dark fs-2 mb-0">Memo</p>
              <p className="text-primary fs-5 fw-400 mb-0 pe-3">
                {stepThreeOptions?.memo}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="mt-7">
        <button
          className="btn text-primary p-0 mb-2"
          type="button"
          onClick={() => {
            showNote && formik.setFieldValue("note", "");
            setShowNote(!showNote);
          }}
        >
          <KTSVG
            path="/media/icons/duotune/communication/com003.svg"
            className="svg-icon-primary svg-icon-1"
          />
          {showNote ? "Remove" : "Add"} note
        </button>
        <Collapse in={showNote}>
          <div className="w-100">
            <WebappTextArea
              maxLength={100}
              row={3}
              placeholder="Note..."
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
};
