import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../app/modules/auth";
import {
  changeAmount,
  formatAmount,
} from "../../app/services/functionServices";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { AssetsContext } from "../../_oxapay/layout/core/AssetsContext";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";
import { WalletAddressInfoButton } from "./WalletAddressInfoButton";
import { WalletAddressQrButton } from "./WalletAddressQrButton";
import { WalletWebappHistoryList } from "./walletWebappHistoryList";
import { WebappTextCopy } from "./webappTextCopy";

export const WalletWebappCoin = () => {
  const { symbol } = useParams();
  const { coinsListMap } = useContext(AppContext);
  const { assets } = useContext(AssetsContext);
  const { setShowAlert, setAlertMessage } = useContext(ShowAlertContext);
  const { currentUser } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;

    const backBtnFn = () => {
      navigate(`/webapp-wallet`);
    };
    WebappFn?.BackButton?.show().onClick(backBtnFn);

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn).hide();
    };
    // eslint-disable-next-line
  }, []);

  const findByCoin = (coinName: string) => {
    const item = assets.find((item) => item.coin === coinName);
    return item;
  };

  return (
    <>
      <div className="pt-14 mb-8 d-flex flex-column justify-content-center align-items-center">
        <p className="fs-4 fw-400 mb-0">
          {symbol && coinsListMap[symbol]?.name} Balance
        </p>
        <div className="d-flex flex-row justify-content-center align-items-center mb-n">
          <p className="fm-sfuirounded fs-45 text-dark fw-600 letter-spacing-n mb-0">
            {symbol &&
              formatAmount(
                findByCoin(symbol)?.amount ?? 0,
                coinsListMap[symbol]?.displayPrecision
              ).split(".")[0]}
            <span className="fs-3x fm-sfuirounded">
              .
              {(symbol &&
                formatAmount(
                  findByCoin(symbol)?.amount ?? 0,
                  coinsListMap[symbol]?.displayPrecision
                ).split(".")[1]) ??
                "00"}
            </span>
          </p>
          <p className="fs-45 text-gray-600 fw-600 mb-0 ms-2 fm-sfuirounded">
            {symbol}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-6">
          <span className={`fs-5 fw-semibold text-dark align-self-start`}>
            {symbol &&
            changeAmount(
              findByCoin(symbol)?.amount ?? 0,
              findByCoin(symbol)?.change ?? 0,
              findByCoin(symbol)?.price ?? 0
            ) < 0
              ? ""
              : "+"}
            $
          </span>
          <span className={`fs-5 fw-semibold text-dark me-2 lh-1`}>
            {symbol &&
              changeAmount(
                findByCoin(symbol)?.amount ?? 0,
                findByCoin(symbol)?.change ?? 0,
                findByCoin(symbol)?.price ?? 0
              ).toFixed(3)}
          </span>
          <span className={`badge badge-success fs-8`}>
            {symbol && (findByCoin(symbol)?.change ?? 0) < 0 ? "" : "+"}
            {symbol && findByCoin(symbol)?.change}%
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-10">
          <WalletAddressInfoButton />
          <WebappTextCopy
            text={currentUser?.wallet || ""}
            onCopy={() => {
              setShowAlert(true);
              setAlertMessage("Address copied to clipoboard");
            }}
          >
            <p className="fm-jetbrain fs-3 m-0">
              <span className="fw-bold">{currentUser?.wallet.slice(0, 3)}</span>
              {currentUser?.wallet.slice(3)}
            </p>
          </WebappTextCopy>

          <WalletAddressQrButton address={currentUser?.wallet as string} />
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center gap-3">
          <Link
            to={`/webapp-wallet/send/${symbol}`}
            className={`btn bg-webapp btn-active-light text-primary fs-7 fw-400 py-3 flex-grow-1 ${
              symbol && !coinsListMap[symbol]?.withdrawEnable && "disabled"
            }`}
          >
            <div className="d-flex flex-column justify-align-center align-items-center">
              <img
                src={toAbsoluteUrl("/media/icons/wallet/send.svg")}
                className="h-20px mb-1"
                alt="send icon"
              />
              <span>send</span>
            </div>
          </Link>
          <Link
            to={`/webapp-wallet/receive/${symbol}`}
            className={`btn bg-webapp btn-active-light text-primary fs-7 fw-400 py-3 flex-grow-1 ${
              symbol && !coinsListMap[symbol]?.depositEnable && "disabled"
            }`}
          >
            <div className="d-flex flex-column justify-align-center align-items-center">
              <img
                src={toAbsoluteUrl("/media/icons/wallet/receive.svg")}
                className="h-20px mb-1"
                alt="receive icon"
              />
              <span>receive</span>
            </div>
          </Link>
        </div>
      </div>
      <div>
        <p className="fs-2x fw-semibold ms-4 mb-2">
          {symbol && coinsListMap[symbol]?.name} trasnfers
        </p>
        <WalletWebappHistoryList coin={symbol} />
      </div>
    </>
  );
};
