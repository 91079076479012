import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { BalanceContext } from "../../_oxapay/layout/core/BlanceContext";
import { WalletWebappSendContext } from "../../_oxapay/layout/core/WalletWebappSendContext";
import { Network } from "./WalletWebappReceive";
import { WebappInput } from "./WebappInput";
import { WebappSelectWithImage } from "./WebappSelectWithImage";
import { errorHandler } from "../../app/services/errorHandler";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { alertsMapSendSubmit } from "../../app/services/alertsMap";
import { formatAmount } from "../../app/services/functionServices";
import { useParams, useSearchParams } from "react-router-dom";
import { Collapse } from "react-bootstrap";

interface props {
  onSuccess: () => void;
  onBack: () => void;
}

export const WalletWebappSendStepTwo = ({ onSuccess, onBack }: props) => {
  const { symbol } = useParams();
  const [searchParams] = useSearchParams();
  const qrAmount = searchParams.get("amount");
  const qrNetwork = searchParams.get("network");
  const { showModal, setShowModal, setComponent } =
    useContext(WebappModalContext);
  const isInitialMount = useRef(true);
  const symbolRef = useRef(false);
  const qrNetworkRef = useRef(false);
  const qrAmountRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { sendAssetSubmit } = useRequest();
  const {
    stepTwoOptions,
    setStepTwoOptions,
    stepThreeOptions,
    setStepThreeOptions,
  } = useContext(WalletWebappSendContext);
  const { coinsListMap } = useContext(AppContext);
  const [selectedCoin, setSelectedCoin] = useState<string | undefined>(
    stepThreeOptions?.coin || undefined
  );
  const [selectedNetwork, setSelectedNetwork] = useState<string | undefined>(
    stepThreeOptions?.network || undefined
  );
  const [networks, setNetworks] = useState<Network[] | undefined>();
  const { balance } = useContext(BalanceContext);
  const [memoEnable, setMemoEnable] = useState(true);
  const [suggest, setSuggest] = useState("");
  const [amountDetail, setAmountDetail] = useState({
    balance: 0,
    fee: 0,
    min: 0,
    max: 0,
  });
  const [optionsCoin, setOptionsCoin] = useState<
    {
      value: string;
      label: string;
      image: string;
      disable: boolean;
    }[]
  >([]);

  useEffect(() => {
    const allowedCoins = Object.keys(stepTwoOptions?.coins ?? []);
    const optionsCoin = Object.entries(coinsListMap)
      .filter(([_symbol, coin]) => coin.status === true)
      .filter(([symbol]) => allowedCoins.includes(symbol))
      .map(([symbol, coin]) => ({
        value: symbol,
        label: `${coin.name} (${symbol})`,
        image: `/media/svg/coins/${coin?.slug}.svg`,
        disable: !stepTwoOptions?.intenal ? !coin.withdrawEnable : false,
      }));

    setOptionsCoin(optionsCoin);
    // eslint-disable-next-line
  }, [coinsListMap]);

  useEffect(() => {
    if (stepThreeOptions) {
      setSelectedCoin(stepThreeOptions.coin);
      setSelectedNetwork(stepThreeOptions.network);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCoin && networks) {
      const coinConfig = stepTwoOptions?.coins[selectedCoin]?.find(
        (coin) => coin.network === (selectedNetwork || networks[0].value)
      );

      if (coinConfig) {
        const Networksuggest = coinConfig.suggest;
        const memoEnable = coinConfig.memoEnable;
        const withdrawFee = coinConfig.withdrawFee;
        const withdrawMin = coinConfig.withdrawMin;
        const withdrawMax = coinConfig.withdrawMax;
        setMemoEnable(memoEnable);
        setSuggest(Networksuggest || "");
        setAmountDetail({
          balance: balance[selectedCoin],
          fee: withdrawFee,
          min: withdrawMin,
          max: withdrawMax,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin, networks, selectedNetwork, balance]);

  useEffect(() => {
    if (symbol && !stepThreeOptions?.coin && !symbolRef.current) {
      if (optionsCoin.some((item) => item.value === symbol)) {
        setSelectedCoin(symbol);
        symbolRef.current = true; // Mark as set
      }
    }
    if (qrNetwork && !stepThreeOptions?.network && !qrNetworkRef.current) {
      if (networks && networks.some((item) => item.value === qrNetwork)) {
        setSelectedNetwork(qrNetwork);
        qrNetworkRef.current = true; // Mark as set
        if (qrAmount && !stepThreeOptions?.amount && !qrAmountRef.current) {
          formik.setFieldValue("amount", Number(qrAmount));
          qrAmountRef.current = true; // Mark as set
        }
      }
    }

    // eslint-disable-next-line
  }, [optionsCoin, networks]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      formik.resetForm({ values: { amount: "", memo: "" } });
    }

    if (selectedCoin) {
      const Coin = coinsListMap[selectedCoin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const allowedNetworks = stepTwoOptions?.coins[selectedCoin].map(
          (item) => item.network
        );

        const optionsNetwork = Object.entries(networks)
          .filter(([symbol]) => allowedNetworks?.includes(symbol))
          .map(([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.withdrawEnable,
          }));

        setNetworks(optionsNetwork);
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin]);

  const maxValidateHandler = () => {
    const maxAmount = Math.min(
      Math.floor(
        (amountDetail.balance - amountDetail.fee + 0.000000000001) * 100000000
      ) / 100000000,
      amountDetail.max
    );

    const finalAmount = Math.max(0, maxAmount);

    return finalAmount;
  };

  const formik = useFormik({
    initialValues: {
      amount: stepThreeOptions?.amount ? stepThreeOptions.amount : "",
      memo: stepThreeOptions?.memo ? stepThreeOptions.memo : "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Amount is required")
        .min(amountDetail.min, "Your amount is less than minimum amount")
        .max(maxValidateHandler(), "Your amount is more than maximum amount"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const res = await sendAssetSubmit(
          stepTwoOptions?.address as string,
          selectedCoin as string,
          stepTwoOptions?.intenal
            ? networks
              ? networks[0].value
              : ""
            : (selectedNetwork as string),
          parseFloat(values.amount),
          values.memo
        );
        if (res.status === 200) {
          setStepThreeOptions({
            address: res.address,
            amount: res.amount,
            coin: res.coin,
            fee: res.fee,
            memo: res.memo,
            network: res.network,
            requestToken: res.requestToken,
            source: res.source,
            twoFa: res.twoFa,
            emailconfirmation: res.emailconfirmation,
          });
          onSuccess();
        } else {
          setShowModal(true);
          setComponent(
            <WebappFailAlert
              content={alertsMapSendSubmit[res.errorNum.toString()]}
            />
          );
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const minHandler = () => {
    const minAmount = Math.min(
      Math.floor(
        (amountDetail.balance - amountDetail.fee + 0.000000000001) * 100000000
      ) / 100000000,
      amountDetail.min
    );

    const finalAmount = Math.max(0, minAmount);

    formik.setFieldValue("amount", finalAmount);
  };

  const maxHandler = () => {
    const maxAmount = Math.min(
      Math.floor(
        (amountDetail.balance - amountDetail.fee + 0.000000000001) * 100000000
      ) / 100000000,
      amountDetail.max
    );

    const finalAmount = Math.max(0, maxAmount);

    formik.setFieldValue("amount", finalAmount);
  };

  useEffect(() => {
    const backBtnFn2 = () => {
      setStepTwoOptions(undefined);
      setStepThreeOptions(undefined);
      onBack();
    };
    const WebappFn = (window as any)?.Telegram?.WebApp;

    WebappFn?.BackButton?.onClick(backBtnFn2).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn2).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;
    if (!showModal) {
      if (loading) {
        WebappFn?.MainButton.show()
          .setText("Please wait")
          .offClick(formik.handleSubmit)
          .showProgress(true);
      } else {
        WebappFn?.MainButton.show()
          .setText("Next")
          .onClick(formik.handleSubmit)
          .hideProgress();
      }
    }

    return () => {
      WebappFn?.MainButton?.hide().offClick(formik.handleSubmit).hideProgress();
    };
    // eslint-disable-next-line
  }, [loading, showModal]);

  return (
    <>
      <div className="pt-15 mb-7">
        <WebappSelectWithImage
          defaultValue="Choose currency"
          options={optionsCoin}
          onChange={(value) => {
            setSelectedNetwork(undefined);
            setSelectedCoin(value);
          }}
          value={selectedCoin || ""}
        />
      </div>
      {!stepTwoOptions?.intenal && networks && (
        <div className="mb-7">
          <WebappSelectWithImage
            defaultValue="Choose network"
            options={networks}
            onChange={(value) => {
              setSelectedNetwork(value);
            }}
            value={selectedNetwork || ""}
          />
        </div>
      )}

      {selectedCoin && (stepTwoOptions?.intenal || selectedNetwork) && (
        <>
          <div className="mb-7">
            <WebappInput
              label="Amount"
              inputMode="decimal"
              labelAddon={
                <>
                  <p className="fs-6 fw-400 text-gray-600 ms-4 mb-0">
                    Balance:{" "}
                    {formatAmount(
                      amountDetail.balance,
                      coinsListMap[selectedCoin]?.displayPrecision
                    )}{" "}
                    {selectedCoin}
                  </p>
                </>
              }
              addon={
                <>
                  <span
                    className="text-primary cursor-pointer fs-3 ms-2 me-3"
                    onClick={minHandler}
                  >
                    Min
                  </span>
                  <span
                    className="text-primary cursor-pointer fs-3 ms-1"
                    onClick={maxHandler}
                  >
                    Max
                  </span>
                </>
              }
              type="number"
              name="amount"
              autoComplete="off"
              placeholder={`min: ${amountDetail.min} ${selectedCoin}`}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.amount}
              error={
                formik.errors.amount && formik.touched.amount ? true : false
              }
              clear={() => formik.setFieldValue("amount", "")}
            />
            {formik.errors.amount && formik.touched.amount && (
              <p className="text-danger fw-semibold ms-3 fs-5 mt-1 mb-0">
                {formik.errors.amount}
              </p>
            )}
            <p className="fs-5 px-5 mt-3 mb-2">
              Trasnfer fee:{" "}
              <span className="text-primary">
                {amountDetail.fee} {selectedCoin}
              </span>
            </p>
            <p className="fs-6 lh-normal m-0 px-5">
              <span className="bg-primary text-white px-2 rounded fs-7 me-1">
                i
              </span>
              The transfer fee will be added to the amount
            </p>
            <Collapse
              in={
                suggest && parseFloat(formik.values.amount) > amountDetail.max
                  ? true
                  : false
              }
            >
              <div className="bg-white rounded py-4 px-5 mt-4">
                <div className="d-flex align-items-center mb-2">
                  <i className="far fa-lightbulb text-primary fs-1 me-1"></i>
                  <span className="fs-3 text-primary fw-bold">Tip</span>
                </div>
                <p className="text-gray-900 mb-1">
                  The amount you've set exceeds the maximum limit.{" "}
                  <b className="text-primary">Consider using the {suggest}</b>{" "}
                  for transferring this amount.
                </p>
              </div>
            </Collapse>
          </div>
        </>
      )}

      {selectedCoin &&
        selectedNetwork &&
        memoEnable &&
        !stepTwoOptions?.intenal && (
          <>
            <div className="mb-7">
              <WebappInput
                label="Memo"
                name="memo"
                autoComplete="off"
                placeholder="Memo (optional)"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.memo}
                error={formik.errors.memo && formik.touched.memo ? true : false}
                clear={() => formik.setFieldValue("memo", "")}
              />
              {formik.errors.memo && formik.touched.memo && (
                <p className="text-danger fw-semibold fs-5 ms-3 mt-1 mb-0">
                  {formik.errors.memo}
                </p>
              )}
              <p className="fs-6 lh-normal m-0 px-5 mt-3">
                <span className="bg-primary text-white px-2 rounded fs-7 me-1">
                  i
                </span>
                Please input any memo you may have here
              </p>
            </div>
          </>
        )}
    </>
  );
};
