import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { errorHandler } from "../../app/services/errorHandler";
import { DateTime } from "../../app/services/functionServices";
import { checkTransferTxStatus } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { SendContext, TxData } from "../../_oxapay/layout/core/SendContext";
import { Check } from "../animatedicons/Check";

interface props {
  show: boolean;
  txData?: TxData;
}
export const TransferInvoiceReceipt = ({ show, txData }: props) => {
  const { setTxData, setShowSuccessModal } = useContext(SendContext);
  const { coinsListMap } = useContext(AppContext);
  const onCloseHandler = () => {
    setTxData(undefined);
    setShowSuccessModal(false);
  };
  const { checkTransferTx } = useRequest();

  useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchStatus = async () => {
        try {
          const response = await checkTransferTx(txData?.id as string, {
            signal,
          });
          if (response.status === 200) {
            setTxData(response.tx);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }
          errorHandler(error as Error);
        }
      };

      if (!txData?.txId) {
        fetchStatus();
        const interval = setInterval(fetchStatus, checkTransferTxStatus);

        return () => {
          controller.abort(); // Cancel the fetch request
          clearInterval(interval); // Clean up the interval on component unmount
        };
      }
    },
    // eslint-disable-next-line
    [txData?.txId, txData?.id]
  );

  return (
    <Modal
      className="custom-modal-rounded"
      show={show}
      onHide={onCloseHandler}
      size={"md" as any}
      centered
    >
      <Modal.Body>
        <Check />
        <div className="mb-10">
          <p className="fs-4 text-center">
            <span className="fw-semibold">Congratulations!</span>
            <br />
            <span className="text-success fw-semibold">
              Trasnfer request was submitted successfully
            </span>
          </p>
        </div>
        <div className="bg-light-secondary ps-6 pt-4 pb-5 rounded mb-5">
          <p className="m-0 fs-4 text-dark">Currency</p>
          <p className="m-0 fs-7 text-primary">
            {txData?.coin && coinsListMap[txData?.coin].name} ({txData?.coin})
          </p>
          <hr className="border-gray-500 my-3" />
          <p className="m-0 fs-4 text-dark">Amount</p>
          <p className="m-0 fs-7 text-primary">
            {txData?.amount} {txData?.coin}
          </p>
          {!txData?.internal && (
            <>
              <hr className="border-gray-500 my-3" />
              <p className="m-0 fs-4 text-dark">Network</p>
              <p className="m-0 fs-7 text-primary">{txData?.network}</p>
            </>
          )}
          <hr className="border-gray-500 my-3" />
          <p className="m-0 fs-4 text-dark">Fee</p>
          <p className="m-0 fs-7 text-primary">
            {txData?.fee} {txData?.coin}
          </p>
          <hr className="border-gray-500 my-3" />
          <p className="m-0 fs-4 text-dark">Receiver address</p>
          <p className="m-0 fs-7 text-primary pe-3 wrap-break-word">
            {txData?.address}
          </p>
          <hr className="border-gray-500 my-3" />
          <p className="m-0 fs-4 text-dark">Transaction Hash</p>
          {txData?.txId ? (
            <a
              href={txData?.txUrl}
              target={"_blank"}
              className="m-0 fs-7 text-primary pe-3 wrap-break-word"
              rel="noreferrer"
            >
              {txData?.txId}
            </a>
          ) : (
            <p className="m-0 fs-7 text-primary pe-3">
              {txData?.txId ? txData?.txId : "Pending"}
            </p>
          )}
          <hr className="border-gray-500 my-3" />
          <p className="m-0 fs-4 text-dark">Date and Time</p>
          <p className="m-0 fs-7 text-primary">
            {DateTime(parseInt(txData?.date as string))}
          </p>
        </div>
        <button
          type="button"
          className="btn btn-primary w-100"
          onClick={onCloseHandler}
        >
          Got it!
        </button>
      </Modal.Body>
    </Modal>
  );
};
