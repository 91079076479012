export const alertsMapLogin: { [key: string]: string } = {
  "-1": "Invalid email address",
  "-2": "Invalid password",
  "-3": "Cannot contact reCAPTCHA. This issue may be due to your connection, VPN, or proxy.",
  "-4": "Incorrect login information. Please try again",
  "-5": "Your account has been suspended. Please contact support for assistance",
  "-8": "Invalid two-factor authentication code. Please try again",
  "-10": "Too many login attempts. Please try again later",
};

export const alertsMapRegister: { [key: string]: string } = {
  "-1": "Invalid email address",
  "-2": "Invalid password",
  "-3": "Password confirmation doesn't match with the password",
  "-4": "Cannot contact reCAPTCHA. This issue may be due to your connection, VPN, or proxy.",
  "-5": "This email is already registered",
  "-6": "Something went wrong. Please try again",
  "-7": "Something went wrong. Please try again",
};

export const alertsMapVerifyEmail: { [key: string]: string } = {
  "-1": "Invalid verify token",
  "-2": "Cannot contact reCAPTCHA. This issue may be due to your connection, VPN, or proxy.",
  "-3": "This link is expired",
  "-4": "Something went wrong. Please try again",
};

export const alertsMapResendEmailAuth: { [key: string]: string } = {
  "-1": "Incorrect email",
  "-2": "Incorrect password",
  "-3": "Something went wrong. Please try again",
  "-4": "Invalid email or password",
  "-5": "This email is already verified",
  "-6": "Your user is suspended",
  "-10":
    "You can request to send an email every 120 seconds. Please try again later",
  "-11":
    "You can request to send an email up to 5 times per hour. Please try again later",
};

export const alertsMapResendEmailByToken: { [key: string]: string } = {
  "-1": "Invalid confirm link",
  "-2": "Incorrect token",
  "-3": "Cannot contact reCAPTCHA. This issue may be due to your connection, VPN, or proxy.",
  "-5": "This email is already verified",
  "-6": "Your user is suspended",
  "-10":
    "You can request to send an email every 120 seconds. Please try again later",
  "-11":
    "You can request to send an email up to 5 times per hour. Please try again later",
};

export const alertsMapResendSetEmailPasswordByToken: { [key: string]: string } =
  {
    "-1": "Invalid confirm link",
    "-2": "Incorrect token",
    "-3": "Cannot contact reCAPTCHA. This issue may be due to your connection, VPN, or proxy.",
    "-4": "The user in not confirmed",
    "-5": "Your user is suspended",
    "-10":
      "You can request to send an email every 120 seconds. Please try again later",
  };

export const alertsMapGoogleLogin: { [key: string]: string } = {
  "1": "Invalid google account",
  "2": "There is some problem in registering with Google account",
  "3": "Your account has been suspended. Please contact support for assistance",
  "4": "Your account is not verified. Check your email to verify your account",
  "6": "Invalid two-factor authentication code. Please try again",
};

export const alertsMapTelegramLogin: { [key: string]: string } = {
  "1": "Invalid telegram account",
  "2": "There is some problem in registering with telegram account",
  "3": "Your account has been suspended. Please contact support for assistance",
  "4": "Your account is not verified. Check your telegram to verify your account",
  "6": "Invalid two-factor authentication code. Please try again",
};

export const alertsMapGoogleLoginVerify: { [key: string]: string } = {
  "-1": "Invalid request token. Please try again.",
  "-2": "Please check your internet connection and try again.",
  "-3": "Incorrect request token. Please try again.",
  "-4": "Invalid login information. Please try again.",
  "-5": "Your account has been suspended. Please contact support.",
  "-6": "Your account is not verified. Check your email to verify your account.",
  "-7": "Two-factor authentication code is required. Please enter the code.",
  "-8": "Incorrect two-factor authentication code. Please try again.",
  "-10": "You have entered too many login requests. Please try again later.",
};

export const alertsMapChangePassword: { [key: string]: string } = {
  "-1": "Please log in first and try again",
  "-2": "The password you entered is invalid",
  "-3": "Your confirmation new password does not match the new password",
  "-4": "The new password you entered is invalid",
  "-5": "The new password you entered is the same as your current password",
  "-6": "The current password you entered is incorrect",
  "-10":
    "You have entered too many incorrect passwords. Please try again later",
};

export const alertsMapProfileUpdate: { [key: string]: string } = {
  "-1": "Please log in first and try again",
  "-2": "The first name you entered is invalid",
  "-3": "The last name you entered is invalid",
  "-4": "The company name you entered is invalid",
  "-5": "The phone number you entered is invalid",
  "-6": "The website address you entered is invalid",
  "-7": "The country you selected is invalid",
};

export const alertsMapEnableTwoFa: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "Two-factor authentication is active now.",
  "-3": "The code you entered is invalid.",
  "-4": "The code you entered is incorrect.",
};

export const alertsMapDisableTwoFa: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": `Two-factor authentication isn't active now.`,
  "-3": "The code you entered is invalid.",
  "-4": "The code you entered is incorrect.",
};

export const alertsMapRecoveryPassword: { [key: string]: string } = {
  "-1": "Sorry, there was a problem with the recovery link. Please request a new link to reset your password.",
  "-2": "The password you entered is invalid. Please enter a valid password.",
  "-3": "The confirmation password does not match the password. Please enter the same password in both fields.",
  "-4": "Please check your internet connection and try again.",
  "-5": "This link for password recovery has expired or has already been used. Please request a new link to reset your password.",
};

export const alertsMapSetEmailPassword: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "Email and password have already been set. You cannot set them again.",
  "-3": "The email you entered is invalid. Please enter a valid email address.",
  "-4": "The password you entered is invalid. Please enter a valid password.",
  "-5": "This email has already been submitted by another user. Please enter a different email address.",
  "-10":
    "You have made too many requests with incorrect data. Please try again later.",
};

export const alertsMapConfirmVerifyEmail: { [key: string]: string } = {
  "-1": "Something went wrong, please try again later.",
  "-2": "Email and password have already been set. You cannot set them again.",
  "-3": "The confirmation link is invalid.",
  "-4": "The confirmation link has expired. Please request to send a new confirmation message.",
  "-5": "This email has already been submitted by another user. Please enter a different email address.",
  "-10":
    "You have made too many requests with incorrect data. Please try again later.",
};

export const alertsMapNewPaymentlink: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "The provided amount is not supported",
  "-4": "No coins selected for the payment link.",
  "-5": "This name has already been entered for another paymentlink. Please enter a different name.",
  "-6": "The commission payer is not valid",
  "-7": "Amount currency is invalid",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapNewDonation: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "No coins selected for the donation.",
  "-4": "This name has already been entered for another donation. Please enter a different name.",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapNewMerchant: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "Logo URL is invalid.",
  "-4": "No coins selected for the merchant.",
  "-5": "Selected commission payer is invalid",
  "-6": "This name has already been entered for another merchant. Please enter a different name.",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapEditMerchant: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-3": "Logo URL is invalid.",
  "-4": "No coins selected for the merchant.",
  "-5": "Selected commission payer is invalid",
  "-6": "This name has already been entered for another merchant. Please enter a different name.",
  "-7": "The merchant you are editing is invalid.",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapEditChannel: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "There is no channel for editing.",
  "-3": "The selected channel is not valid.",
  "-4": "The period of one of the plans is not valid (period must be between 1-60).",
  "-5": "There are 2 plans with the same period.",
  "-6": "There are 2 plans with the same price.",
  "-7": "The provided amount is not supported",
  "-10": "Something went wrong. Please try again.",
};

export const alertsMapNewPayoutApi: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "Daily limit is less than the allowed amount",
  "-4": "limit per transactions is less than the allowed amount",
  "-5": "This name has already been entered for another payout API key. Please enter a different name.",
  "-6": "Two-factor authentication is not enabled. Please activate it and try again.",
  "-7": "Please enter the Two-factor authentication code.",
  "-8": "The Two-factor authentication code is invalid.",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapEditPayoutApi: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "Daily limit is less than the allowed amount",
  "-4": "limit per transactions is less than the allowed amount",
  "-5": "This name has already been entered for another payout API. Please enter a different name.",
  "-6": "The payout API you are editing is invalid.",
  "-7": "Two-factor authentication is not enabled. Please activate it and try again.",
  "-8": "Please enter the Two-factor authentication code.",
  "-9": "The Two-factor authentication code is invalid.",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapDeleteItem: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "There is no item to delete",
  "-3": "The selected item for delete is not valid",
};

export const alertsMapUserReq: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
};

export const alertsMapSendCheckAddres: { [key: string]: string } = {
  "-2": "Please enter the receiver's address.",
  "-3": "The address is incorrect or not supported in OxaPay.",
  "-4": "You cannot send the asset to yourself.",
  "-15":
    "Two-factor authentication is not enabled. Please activate it and try again.",
};

export const alertsMapSendSubmit: { [key: string]: string } = {
  "-2": "The recipient's address is not entered.",
  "-3": "The address is incorrect or not supported by OxaPay.",
  "-4": "You cannot send the asset to yourself.",
  "-5": "The selected coin or network is incorrect.",
  "-6": "The selected coin is not supported for the entered address.",
  "-7": "The selected network is not supported for the entered address.",
  "-8": "The amount was not entered.",
  "-9": "The amount you entered is less than the minimum required amount.",
  "-10": "Your balance is insufficient to perform the transfer.",
  "-15": "The trasnfer of this currency on defined network is disabled",
};

export const alertsMapSendSign: { [key: string]: string } = {
  "-2": "Invalid request. Please check your input.",
  "-3": "Request expired. Please try again later.",
  "-4": "Incorrect verification code. Please try again.",
  "-5": "Two-factor authentication code is required.",
  "-6": "Incorrect two-factor authentication code. Please try again.",
  "-10": "Insufficient balance to perform the transfer.",
};

export const alertsMapSwap: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "Invalid source (From) currency.",
  "-3": "Invalid target (To) currency.",
  "-4": "The selected currency pair is unavailable or inactive.",
  "-5": "The amount is below the minimum required.",
  "-6": "Insufficient balance to complete the transaction.",
  "-7": "The price has changed. Please try again.",
  "-500": "A system error occurred. Please try later.",
};

export const alertsMapChangeAddressList: { [key: string]: string } = {
  "-11": "Two-Factor is not active",
  "-12": "Invalid two-factor authentication code.",
  "-100": "A system error occurred. Please try later.",
};

export const alertsMapTxAction: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "Invalid action type.",
  "-3": "This action is currently unavailable for you.",
  "-4": "Deposit ID is not entered.",
  "-5": "Invalid deposit ID provided.",
  "-6": "Transaction status has been updated.",
};

export const alertsMapAddAddressbook: { [key: string]: string } = {
  "-1": "Please log in first before trying again.",
  "-2": "The name you entered is invalid.",
  "-3": "The address you entered is invalid.",
  "-4": "You cannot add your own address to the Address Book.",
  "-5": "This address already exists in your Address Book.",
  "-6": "This name already exists in your Address Book.",
  "-7": "A two-factor code is required for this action.",
  "-8": "The two-factor code you entered is not correct.",
  "-10": "Something went wrong. Please try again later.",
};

export const alertsMapEditAddressbook: { [key: string]: string } = {
  "-1": "Please log in first before trying again.",
  "-2": "The name you entered is invalid.",
  "-3": "The address you entered is invalid.",
  "-4": "You cannot add your own address to the Address Book.",
  "-5": "This address already exists in your Address Book.",
  "-6": "This name already exists in your Address Book.",
  "-7": "A two-factor code is required for this action.",
  "-8": "The two-factor code you entered is not correct.",
  "-9": "You don't have access for this action.",
  "-10": "Something went wrong. Please try again later.",
};

export const alertsMapNewGeneralApi: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "This name has already been entered for another general API key. Please enter a different name.",
  "-4": "Two-factor authentication is not enabled. Please activate it and try again.",
  "-5": "Please enter the Two-factor authentication code.",
  "-6": "The Two-factor authentication code is invalid.",
  "-10": "Somethings went wrong, please try again",
};

export const alertsMapEditGeneralApi: { [key: string]: string } = {
  "-1": "Please log in first and try again.",
  "-2": "The name you entered is invalid.",
  "-3": "This name has already been entered for another general API key. Please enter a different name.",
  "-4": "Two-factor authentication is not enabled. Please activate it and try again.",
  "-5": "Please enter the Two-factor authentication code.",
  "-6": "The Two-factor authentication code is invalid.",
  "-7": "You don't have access for this action.",
};
